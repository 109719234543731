import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import React from "react";
import style from './Header.module.scss';
import KeraLabLogo from '../assets/keralab-logo.svg';
import Image from "./Image";

export default function Header() {

    return <header className={style.headerContainer}>
        <Image src={KeraLabLogo} clickUrl={"/"} height={70} alt={"KeraLab logo"}/>
        <div className={style.menuContainer}>
            <div className={style.menuItem}><Link to="/">Home</Link></div>
            <div className={style.menuItem}><HashLink smooth to="/#services">Services</HashLink></div>
            <div className={style.menuItem}><HashLink smooth to="/#projects">Projects</HashLink></div>
            <div className={style.menuItem}><HashLink smooth to="/#contact">Contact</HashLink></div>
        </div>
    </header>;
}
