import style from './ServiceDetails.module.scss';

interface ServiceDetailsProps {
    title: string
    description: string
    thumbnail: string
}

export default function ServiceDetails(props: ServiceDetailsProps) {
    return <div className={style.serviceDetailsContainer}>
        <img className={style.thumbnail} src={props.thumbnail}/>
        <h1>{props.title}</h1>
        <p>{props.description}</p>
    </div>
}
