import style from './ProjectList.module.scss';
import { SRLWrapper } from 'simple-react-lightbox';

export default function ProjectList() {
    const projects = [{
        src: "http://www.avenus.si/pics/keramika2.jpg",
        description: "Kopalnica"
    }, {
        src: "http://www.avenus.si/pics/keramika.jpg",
        description: "Custom product."
    }, {
        src: "http://www.avenus.si/pics/Slika07.jpg",
        description: "Custom product."
    }, {
        src: "http://www.avenus.si/pics/Slika05.jpg",
        description: "Custom product."
    }, {
        src: "http://www.avenus.si/pics/Slika09.jpg",
        description: "Custom product."
    }, {
        src: "http://www.avenus.si/pics/Slika06.jpg",
        description: "Custom product."
    }]

    return <div className={style.projectListContainer}>
        <SRLWrapper>
            <div className={style.projectListContainer}>
                {projects.map((project, i) =>
                    <div key={i}
                         className={style.imageContainer}
                         style={{backgroundImage: `url("${project.src}")`}}>
                        <a href={project.src}>
                            <img className={style.image} src={project.src} alt={project.description}/>
                        </a>
                    </div>
                )}
            </div>
        </SRLWrapper>
    </div>
}
