import style from './HeadlineImage.module.scss';
import sampleImage from '../assets/water-jet-cutting.webp';

interface HeadlineImageProps {
    title: string
}

export default function HeadlineImage(props: HeadlineImageProps) {
    return <div className={style.headlineContainer}>
        <div className={style.imageContainer}
             style={{backgroundImage: `url("${sampleImage}")`}}>
            <div className={style.title}>{props.title}</div>
        </div>
    </div>
}
