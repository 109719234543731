import TitlePanel, { PanelTheme } from "./TitlePanel";
import Image from "./Image";
import AvenusLogo from '../assets/avenus-logo.svg'
import style from './Partners.module.scss'

export default function Partners() {
    return <TitlePanel title={"Partners"} theme={PanelTheme.dark}>
        <div className={style.partnersContainer}>
            <div className={style.imageContainerBorder}>
                <div className={style.imageContainer}>
                    <Image src={AvenusLogo}
                           height={50}
                           clickUrl={"https://www.avenus.si/"}/>
                </div>
            </div>

            <div className={style.imageContainerBorder}>
                <div className={style.imageContainer}>
                    <Image
                        src={"https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Geberit-Logo.svg/1200px-Geberit-Logo.svg.png"}
                        height={30}
                        clickUrl={"https://www.geberit.si/"}/>
                </div>
            </div>

            <div className={style.imageContainerBorder}>
                <div className={style.imageContainer}>
                    <Image src={"https://www.abk.it/img/ABK_logoB.svg"}
                           height={50}
                           clickUrl={"https://www.abk.it/"}/>
                </div>
            </div>
        </div>
    </TitlePanel>
}
