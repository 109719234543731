import style from './ServiceList.module.scss'
import { useNavigate } from "react-router-dom";
import Service from "./Service";
import WaterJetCuttingThumbnail from "../assets/water-jet-cutting.webp";
import ThreeDRoomScan from "../assets/3d-room-scan.jpeg"
import CustomProduct from "../assets/custom-product.jpeg"

export default function ServiceList() {
    const navigate = useNavigate();

    return <div className={style.serviceList}>
        {/*TODO: Move into list of properties*/}
        <Service
            onClick={() => navigate("/services/water-jet-cut")}
            title={"Water Jet Cut"}
            description={"Sophisticated water jet cuts even the thinnest tiles."}
            thumbnail={WaterJetCuttingThumbnail}/>
        <Service
            onClick={() => navigate("/services/3d-room-scan")}
            title={"3D Room Scan"}
            description={"We can scan your room and make a custom 3D design and visualisation."}
            thumbnail={ThreeDRoomScan}/>
        <Service
            onClick={() => navigate("/services/custom-product")}
            title={"Custom Product"}
            description={"Make any custom ceramic product that you desire."}
            thumbnail={CustomProduct}/>
    </div>
}
