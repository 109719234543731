import { Link } from "react-router-dom";

export default function NoRouteFound() {
    return <div>
        <h2 style={{fontWeight: 'bold', textAlign: 'center'}}>Stran, ki jo iščete ne obstaja.</h2>
        <div style={{textAlign: 'center'}}>
            <Link to={"/"} style={{color: '#222', fontSize: '20px', textAlign: 'center'}}>Domov</Link>
        </div>
    </div>
}
