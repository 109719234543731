import style from './TitlePanel.module.scss';
import { ReactElement } from "react";

export enum PanelTheme {
    dark, light
}

interface TitlePanelProps {
    children: ReactElement | ReactElement[];
    theme?: PanelTheme
    title: string
}

export default function TitlePanel(props: TitlePanelProps) {
    const themeClass = (props.theme ?? PanelTheme.light) === PanelTheme.light ? style.panelLight : style.panelDark;
    return <div className={style.panel + " " + themeClass}>
        <h1 className={style.panelTitle}>{props.title}</h1>
        <div className={style.titleLine}/>
        <div className={style.panelContent}>
            {props.children}
        </div>
    </div>
}
