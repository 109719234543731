import { useNavigate } from "react-router-dom";

interface ImageProps {
    src: string
    height: number
    clickUrl?: string
    alt?: string
}

export default function Image(props: ImageProps) {
    const navigate = useNavigate();
    const openInNewTab = (url: string): void => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) {
            newWindow.opener = null
        }
    }

    const onImageClick = () => {
        if (props.clickUrl === undefined || props.clickUrl.length === 0) {
            return;
        } else if (props.clickUrl.startsWith("http://") || props.clickUrl.startsWith("https://")) {
            openInNewTab(props.clickUrl);
        } else {
            navigate(props.clickUrl);
        }
    }

    const elementStyle = props.clickUrl ? {cursor: 'pointer'} : {};
    return <img height={props.height}
                src={props.src}
                style={elementStyle}
                onClick={onImageClick}
                alt={props.alt ? props.alt : props.src}/>
}
