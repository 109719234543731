export default function TriangleSeparatorTop() {
    return <div style={{
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 0 40px 100vw',
    borderColor: 'transparent transparent #eee transparent'
}
}/>
}
