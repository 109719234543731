import style from "./Service.module.scss";

interface ServiceProps {
    title: string
    description: string
    thumbnail: string
    onClick: () => void
}

export default function Service(props: ServiceProps) {
    return <div
        className={style.service}
        onClick={props.onClick}>
        <div className={style.imageContainer}>
            <img className={style.image} src={props.thumbnail}/>
        </div>
        <div className={style.title}>{props.title}</div>
        <div className={style.titleSeparator}/>
        <div className={style.description}>{props.description}</div>
    </div>
}
